<template>
  <div class="box" style="margin: -15px -15px">
    <div class="Previous_left">
      <div class="Previous_module">模块</div>
      <a-menu style="width: 100%" v-model="selectId" mode="inline">
        <a-menu-item
            v-for="(item, index) in templateList"
            @click="selectStatus(item.id,item.name)"
            :key="item.id"
        >
          {{ item.name }}
        </a-menu-item>
      </a-menu>
      <div style="display: flex;justify-content: right;padding: 5px">
        <a-pagination
            size="small"
            v-model="temPosition.pageNum"
            :total="temPosition.total"
            @change="menuPageChange"/>
      </div>
    </div>
    <div class="Previous_right">
      <div class="Previous_right_title">用户经验值获得记录</div>
      <div style="display: flex;padding: 10px;justify-content: space-between;">
        <div>
          <span>日期：</span>
          <a-range-picker v-model="datePicker" style="margin-right: 15px;width: 220px" @change="onChange" />
        </div>
        <div>
          <a-button
              @click="exportFunc('mx', selectId[0])"
              style="margin-left: 10px">
            <a-icon type="file-excel" style="color: #1ea134;font-size: 16px" />
            导出明细
          </a-button>
          <a-button
              @click="exportFunc('mx')"
              style="margin-left: 10px">
            <a-icon type="file-excel" style="color: #1ea134;font-size: 16px" />
            导出全部明细
          </a-button>
          <a-button
              @click="exportFunc('mk', selectId[0])"
              style="margin-left: 10px">
            <a-icon type="file-excel" style="color: #1ea134;font-size: 16px" />
            导出该模块
          </a-button>
          <a-button
              @click="exportFunc('mk',)"
              style="margin-left: 10px">
            <a-icon type="file-excel" style="color: #1ea134;font-size: 16px" />
            导出全部模块
          </a-button>
        </div>
      </div>
      <a-spin :spinning="spinning">

      <a-table
          style="margin: 10px"
          :columns="tableColumns"
          :data-source="tableData"
          :rowKey="record=>record.id"
          :pagination="false">

      </a-table>
      </a-spin>

      <div   style="display: flex;justify-content: space-between;margin: 10px">
                    <span style="font-size: 17px;color: #929292">共 {{ pagination.total }} 条记录 第 {{
                        pagination.current
                      }} / {{ Math.ceil(pagination.total / pagination.pageSize) }} 页</span>
        <a-pagination v-model="pagination.current"
                      :total="pagination.total"
                      :page-size="pagination.pageSize"
                      showQuickJumper
                      showSizeChanger
                      @showSizeChange="paginationChange"
                      @change="paginationChange" />
      </div>
    </div>
  </div>
</template>

<script>
import {getExpExportApi, getExpExportModeApi, getExpTableDataApi, getModuleMenu} from "@/service/titleExam_api";
import api_header_config from "@/service/api_header_config";
export default {
  name: "ExperienceValueRecord",
  data () {
    return {
      datePicker:null,
      selectId: [],
      templateList: [],
      temPosition:{
        pageNum:1,
        pageSize:10,
        total:0,
      },
      spinning: false,
      pagination:{
        total:0,
        pageSize: 10,
        current:1,
      },
      tableData: [],
      tableColumns: [
        {
          title: '姓名',
          dataIndex: 'memberName'
        },
        {
          title: '医院',
          dataIndex: 'hospitalName'
        },
        {
          title: '科室',
          dataIndex: 'hospitalOffice'
        },
        {
          title: '职称',
          dataIndex: 'jobTitle'
        },
        {
          title: '日期',
          dataIndex: 'createdTimeHT'
        },
        {
          title: '获得经验值',
          dataIndex: 'value'
        },
      ],
      endTime: null,
      startTime: null,
      header: api_header_config.api_header
    }//return
  },
  created() {
    this.getLeftTemplate(); //获取左侧菜单列表
  },
  methods: {
    async getTableData () {
      this.spinning = true
      const data = {
        typeId: this.selectId[0],
        page: this.pagination.current,
        pageSum: this.pagination.pageSize,
        beginDate: this.startTime,
        endDate: this.endTime
      }
      const res = await getExpTableDataApi(data)
      if (res.code === 200) {
        this.spinning = false
        this.tableData = res.data
        this.pagination.total = res.count
      }
    },
    onChange(date, dateString) {
      this.startTime = dateString[0];
      this.endTime = dateString[1];
      this.pagination.current = 1;
      this.getTableData()
    },
    //导出
    async exportFunc (type, id) {
      if (this.selectId.length === 0) {
        this.$message.warning('数据异常请刷新页面')
        return
      }
      // if (this.tableData.length === 0) {
      //   this.$message.warning('暂无数据')
      //   return
      // }
      if (type === 'mx') {
        if (id) {
          const url = this.header + '/v3/admin/ex/chart/record/exportMingXi?typeId=' + id + `${this.startTime ? '&beginDate=' + this.startTime : ''}` + `${this.endTime ? '&endDate=' + this.endTime : ''}`
          console.log(url)
          window.open(url)
        } else {
          const url = this.header + '/v3/admin/ex/chart/record/exportMingXi'  + `${this.startTime ? '?beginDate=' + this.startTime : ''}` + `${this.endTime ? '&endDate=' + this.endTime : ''}`
          console.log(url)
          window.open(url)
        }
      } else if (type === 'mk') {
        if (id) {
          const url = this.header + '/v3/admin/ex/chart/record/exportMock?typeId=' + id  + `${this.startTime ? '&beginDate=' + this.startTime : ''}` + `${this.endTime ? '&endDate=' + this.endTime : ''}`
          console.log(url)
          window.open(url)
        } else {
          const url = this.header + '/v3/admin/ex/chart/record/exportMock'  + `${this.startTime ? '?beginDate=' + this.startTime : ''}` + `${this.endTime ? '&endDate=' + this.endTime : ''}`
          console.log(url)
          window.open(url)
        }
      }
    },
    //获取左侧菜单列表
    async getLeftTemplate() {
      let data = {
        page:this.temPosition.pageNum
      }
      const res = await getModuleMenu(data);
      if (res.code === 200) {
        this.templateList = res.data;
        this.selectId[0] = res.data[0].id;
        this.selectTemName = res.data[0].name;
        this.temPosition.total = res.count;
        await this.getTableData()
      }
    },
    async menuPageChange(current, pageSize) {
      this.temPosition.pageNum = current;
      await this.getLeftTemplate();
    },
    selectStatus(value,name) {
      this.selectId[0] = value;
      this.pagination.current = 1;
      this.pagination.pageSize = 10;

      this.startTime = null;
      this.endTime = null;
      this.datePicker = null;
      this.getTableData()
    },
    paginationChange (value, value2) {
      this.pagination.current = value
      this.pagination.pageSize = value2
      this.getTableData()
    },
  }//methods
}
</script>

<style lang="scss" scoped>
.Previous_left{
  width: 20%;
  //background-color: #ee7474;
  .Previous_module{
    margin-left: 25px;
    margin-top: 10px;
  }
}
.box {
  min-height: 110%;
  display: flex;
  justify-content: space-between;
}
.Previous_right{
  border-left: 15px solid #F0F2F5;
  width: 80%;
  .Previous_right_top{
  }
  .Previous_right_title{
    width: 98%;
    margin: auto;
    font-weight: bold;
    color: #333333;
    padding-top: 15px;
  }
  .top_cont{
    width: 98%;
    margin: auto;
    display: flex;
    justify-content: space-between;
  }
  .EduRec-top-input{
    height: 50px;
    padding-top: 15px;
  }

  .activity-table{
    width: 98%;
    margin: auto;
    padding-top: 20px;
  }
  .pageView{
    display: flex;
    justify-content: space-between;
    z-index: 3;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .pageLeft{
    font-size: 15px;color: #929292
  }
}
</style>